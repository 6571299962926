import React from "react";

import SEO from "../components/seo";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="container">
      <h1 className="text-center">NOT FOUND</h1>
      <p className="text-center">This page dose not exist</p>
      <h2></h2>
    </div>
  </>
);

export default NotFoundPage;
